@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

nav {
  height: 69px;
  background: #00bcd4;
  position: relative;
}

nav > .container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  max-width: 1320px;
  margin: auto;
}

.mintUpContainer {
  display: flex;
  justify-content: center;
  /* padding-bottom: 70px; */
  flex-direction: column;
  align-items: center;
  display: flex;
  margin-bottom: 70px;
  position: absolute;
  left: 13.2%;
  bottom: 0%;
}

.calc-total-supply {
  position: absolute;
  left: 20%;
  top: 17%;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calc-total-supply > p {
  font-size: 2.5rem;
  font-family: "calculatorfont";
}

.mintButton {
  background: transparent;
  padding: 0;
  outline: none;
  border: none;
}

.calc-mint-increment-button-group {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  position: absolute;
  width: 100%;
  position: absolute;
  top: 50%; 
  left: 50%; 

  transform: translate(-50%, -5%); */
}

.decrement-mint-button-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.decrement-mint-button-flex {
  display: flex;
  cursor: pointer;
}

.increment-mint-button-container {
}

.button-group {
  display: flex;
  margin-bottom: 8%;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: auto;
  margin-top: auto;
  padding-top: 15%;
}

.increment-mint-button-flex {
  display: flex;
  cursor: pointer;
}
.decrement-mint-button {
  width: 65px;
}
.increment-mint-button {
  width: 65px;
}
.calc-mint-amount > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  height: 100%;
  font-family: "calculatorfont";
  font-size: 2.5rem;
  align-items: center;
}
.increment-mint-button-container {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

.calc-mint-increment-button-group > div > img {
  width: 65px;
}

.calc-mint-button {
  width: 329px;
  cursor: pointer;
}

.nav-banner {
}

.mint-section-wrapper {
  position: absolute;
  top: 40%;
  left: 57%;
}
#slidingbanner {
  width: 100%;
  position: absolute;
  background: url(./images/roundguysbanner2.png) 0% 80%;
  margin: 0;
  background-size: 100vw auto;
  animation: moves 8s infinite linear;
  height: 100%;
  overflow: hidden;
  background-repeat: repeat-x;
}

.team-header {
  padding-top: 14vw;
}

@keyframes moves {
  to {
    background-position: -100vw 80%;
  }
}

.footer {
  /* margin-top: 5vw; */
  width: 100%;
  background-image: url("./images/ground.png");
  height: 160px;
  background-size: cover;
  background-repeat: no-repeat;
  image-rendering: pixelated;
  /* background-color: #00bcd4; */
  /* background: #076c6c; */
}
.nav-brand {
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

.hideElement {
  display: none;
}
.nav-collapse {
  display: flex;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
}

.nav-links {
  margin-left: auto;
  margin-right: auto;
}

.nav-items {
  color: #fff !important;
  margin-left: 3rem;
  margin-right: 3rem;
  font-size: 1vw;
  text-decoration: none;
}

#navLogo {
  width: 18rem;
  height: auto;
}
.paper {
  /* margin-right: 7vw; */
  transform: rotate(346deg);
}

.clouds-gif {
  position: relative;
  height: 95vh;
  min-width: 100%;
  background-image: url("./images/clouds-slow.gif") !important;
  /* background-color: #ccc; */
  background: #00bcd4;
  background-position: 50 !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  image-rendering: pixelated;
}
.landing-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-text-container {
  font-size: 1.85vw;
  width: 50%;
  font-family: "RoundGuysLight", "Poppins", "Roboto", sans-serif;
}

#mainLogo {
  width: 40%;
  margin-top: 25vh;
}

.calc-img-container {
  position: relative;
  width: 100%;
  background-image: url("./images/Calculator.gif");
  height: 510px;
  width: 448px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* box-shadow: -2px 20px 14px 7px #00000029; */
}

.dither {
  background-repeat: no-repeat;
  /* background-image: url("./images/dither_lightblue.png"); */
  height: 3vh;
  /* background-color: black; */
  background-size: cover;
  width: 100vw;
}
.reverse {
  transform: rotate(180deg);
}
.mint-top {
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: center;
  /* border: 1px dashed white; */
  /* background: #68c8df; */
  margin-top: 5vh;
  /* background: black; */
}
.mint-button {
  height: 10vw;
  width: 27vw;
  margin-left: 7vw;
  margin-right: 2vw;
  border-radius: 20px;
  border: none;
  font-size: 3vw;
  font-family: inherit;
  background: rgba(0, 0, 0, 20%);
  border: 5px solid white;
}
.mint-top-content {
  display: flex;
  align-items: center;
}

.flipper-img-container > img {
  width: 100%;
  vertical-align: auto;
}
.mint-main {
  margin-top: 10vh;
  background-color: inherit;
  width: 50%;
}

.mint-container {
  display: flex;
  flex-direction: row;
}

.mint-content-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.mint-content {
  font-family: "Chicken-scratch", "Poppins", "Roboto", Arial, sans-serif;
  font-size: 2vw;
}

.mint-content-text {
}

.mint-img {
  width: 100%;
  image-rendering: pixelated;
}

.about-header {
  font-size: 3vw;
  text-align: center;
  padding-top: 8vw;
  margin-bottom: 1vw;
}
.about-section {
  font-family: "Chicken-scratch", "Poppins", "Roboto", sans-serif !important;
  /* margin-top: 8vw; */
  background: #076c6c;
}

.about-gif {
  height: 100vh;
  min-width: 100%;
  background-image: linear-gradient(180deg, transparent 60%, rgb(255, 255, 255)),
    linear-gradient(0deg, transparent 60%, #ffffff),
    url("./images/TheRoundGuysBannerLargeNoClouds.png");
  background-color: #ccc;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  box-sizing: border-box;
}

.about-main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.about-text-container {
  display: flex;
  height: 45%;
  width: 30%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 6rem;
  color: #fff;
}

/* .about-header {
  font-size: 2.8rem;
  height: 5rem;
} */
.about-text {
  font-family: "Chicken-scratch", "Roboto";
  font-size: 2rem;
  text-align: left;
}

.roadmap-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  /* background-color: transparent; */
  background: #000000bf;
}

.roadmap-header-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%;
  top: 0;
  width: 100%;
  padding: 20px;
}

.roadmap-header {
  color: white;
  font-size: 6vw;
}

.roadmap-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: -webkit-max-content;
  height: max-content;
  align-items: center;
  width: 80%;
}

.roadmap-strip {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: -webkit-max-content;
  height: max-content;
  min-height: 100vh;
  padding: 0px 50px;
}

.roadmap-strip-head {
  display: flex;
  justify-content: flex-start;
  color: white;
  font-size: 2vw;
}
.roadmap-strip-subHead {
  display: flex;
  justify-content: flex-start;
  color: white;
  font-size: 3.4vw;
}

.roadmap-strip-desc {
  color: white;
  /* font-size: 1.69vw; */
  margin-bottom: 4rem;
  margin-left: 2rem;
  /* font-size: 2.69vw !important; */
}

.roadmap-strip-desc > p {
  font-size: 1.75vw !important;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 2.5rem;
}
.roadmap-strip-desc > span {
  font-size: 1.75vw !important;
  line-height: 2.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.roadmap-strip-desc > h2 {
  margin: 1.3rem 0rem;
  /* font-size: 1.8rem; */
  font-size: 2vw;
}

.roadmap-strip-desc > ul > li {
  margin-bottom: 1.5rem;
  list-style-type: none;
}

.airdrops-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-repeat: repeat-y;
  background-size: contain;
  background-color: transparent;
  background: #000000bf;
  text-align: center;
  height: -webkit-max-content;
  height: max-content;
}

.airdrop-title {
  color: white;
  padding-bottom: 3rem;
  font-size: 5.8vw;
  margin-top: 3rem;
  text-align: center;
}

.roadmap-animation {
  animation: fadeIn ease 5s;
  will-change: transform;
}

.airdrop-animation {
  animation: fadeIn ease 5s;
}
.airdrop-header {
  margin-top: 5rem;
  text-align: center;
}
.airdrop-content-container {
  margin-bottom: 6rem;
  text-align: left;

  font-size: 1.9vw !important;
  line-height: 3rem;
  display: flex;
  justify-content: center;

  margin-left: -2rem;
  margin-top: 2rem;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.roadmap-dropdown {
  position: fixed;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: 100vh;
  background-image: url("./images/Space.gif");
  background-size: contain;
  image-rendering: pixelated;
  color: white;
  z-index: 100;
  overflow: hidden;
  animation-fill-mode: forwards;
  transform: translateY(-110%);
  will-change: transform, z-index;
}

.finkels-img {
  /* border: 2px solid white; */
  border-radius: 5px;
  padding: 10px;
  width: 50%;
}

.art-url {
  color: white;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}

.roadmap-mint-bg {
  background: black;
  background-image: url("./images/mint-page-new.png") !important;
  background-size: cover;
  image-rendering: pixelated;

  background-position: center;
  background-repeat: no-repeat;
}
.roadmap-team-bg {
  background: black;
  background-image: url("./images/team-page-1.png") !important;
  background-size: cover;
  image-rendering: pixelated;

  background-position: center;
  background-repeat: no-repeat;
}

.bio-one {
  position: absolute;
  top: 72%;
  left: 24%;
  transform: translate(-24%, -72%);
}
.bio-two {
  position: absolute;
  top: 72%;
  left: 58.5%;
  transform: translate(-30%, -72%);
}
/* .twitter-link:hover {
  font-size: 2rem;
} */
.bio-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.75vw;
  font-size: 1.2vw;
}
/* 1280x800, 1920x1200 */

/* 1920x1080 */

/* 1280x1024 */
@media (aspect-ratio: 5/4) {
  .roadmap-mint-bg {
    background-size: contain;
  }
}
/* 2160x1440, 2560x1700† */
@media (aspect-ratio: 3/2) {
  .roadmap-mint-bg {
    background-size: cover;
  }
  .roadmap-team-bg {
    background-size: cover;
  }
}
/* 1024x768, 1600x1200 */
@media (aspect-ratio: 4/3) {
  .roadmap-mint-bg {
    background-size: contain;
  }
  .roadmap-team-bg {
    background-size: cover;
  }
}
@media (aspect-ratio: 16/10) {
  .roadmap-mint-bg {
    background-size: cover;
  }
  .roadmap-team-bg {
    background-size: cover;
  }
}

@media (aspect-ratio: 16/9) {
  .roadmap-mint-bg {
    background-size: contain;
  }
}

.roadmap-dropdown::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: auto;
  color: #fff;
}

.roadmap-details {
  display: flex;
  flex-direction: column;
  color: white;
  padding: 0;
  display: flex;
  overflow-y: scroll;
  scroll-behavior: smooth;
  background: white;
}

.roadmap-list {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0rem 2rem;
  counter-reset: li;
  font-family: "RoundGuysLight", "Poppins", "Roboto", sans-serif;
  text-transform: uppercase;
}

.roadmap-list > li {
  display: grid;
  grid-template-columns: 3.4722222222vw 1fr;
  justify-content: start;
  align-items: start;
  margin-bottom: 1.0416666667vw;
}

.roadmap-list > li:hover {
  cursor: pointer;
}
.roadmap-list > li > a {
  font-size: 5vw;
  padding-left: 3rem;
  line-height: 80%;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 500;
}

.startAnimationButton {
  background: none;
  border: none;
  outline: none;
  z-index: 9999;
}

.roadmap-list > li::before {
  counter-increment: li;
  content: counter(li);
  font-size: 2.7083333333vw;
  font-weight: 700;
  /* line-height: 100%; */
  letter-spacing: -0.01em;
  text-transform: uppercase;
}

.animation {
  animation: dropdown 0.5s forwards;
}

.animationReverse {
  animation: dropdownReverse 0.5s forwards;
}

.closeContainer {
  position: absolute;
  padding: 1.4vw;
  z-index: 9999;
}
.close {
  outline: none;
  color: rgba(255, 254, 254, 0.453);
  border: none;
  background: transparent;
  font-size: 2vw;
  border-radius: 100%;
  cursor: pointer;
  font-weight: bolder;
}

.close:hover {
  color: white;
}

.team-main {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.team-image {
  width: 10vw;
}

.team-header {
  font-size: 5vw;
  font-family: "RoundGuysLight", "Poppins", "Roboto", sans-serif;
}

.team-container {
  margin-top: 5vw;
}

.team-content {
  display: flex;
  justify-content: center;
}
.team-member {
  width: 20vw;
  display: inline-block;
  padding: 40px;
}

.team-member-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.25em;
}
.team-member-avatar {
  /* width: 80%; */
  margin: auto;
}
.team-member-avatar > img {
  width: 100%;
}

.team-member-socials {
  margin-top: 2vw;
}
#mint {
  margin-top: 6vw;
  display: flex;
  justify-content: center;

  flex-direction: column;
  align-items: center;
}

#team {
  /* margin-top: 10vw; */
  /* background: #076c6c; */
  background: rgba(255, 254, 254, 0.453);
}

.social-links-nav {
  margin-left: auto;
}

.social-link {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}
.social-link > img {
  width: 100%;
}
.social-img {
  height: 2.2rem;
}

.twitter-img {
  height: 1.5rem;
  padding-bottom: 4px;
}

/* .about {
  max-width: 80vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
} */

.about-container {
  display: flex;
  justify-content: center;
}

.about-flex-container {
  display: flex;
  width: 64%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.about-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 17px;
  border-radius: 5px;
}

.about-flex-item {
  display: flex;
  align-items: center;
  max-width: 40vw;
}

.about-flex-item > img {
  max-width: 13vw;
}

.about-flex-item-text {
  font-size: 1.5vw;
  flex: 1;
  padding-left: 5rem;
}

.dot {
  position: absolute;
  left: 30%;
  /* background-image: url("./images/bannerguy1.png");
  background-color: black; */
  width: 80px;
  height: 80px;
  background-size: contain;

  /* animation: xAxis 7s infinite; */
}

.roundGuyPeeker {
  position: absolute;
  background-image: url("./images/bannerguy2.png");
  background-size: contain;
  width: 600px;
  height: 600px;
  left: -40%;
  bottom: -80%;
}
.showRoundGuy {
  animation: peak 0.5s ease-in;
  animation-fill-mode: forwards;
  /* animation-direction: reverse; */
}
.hideRoundGuy {
  animation: peakAway 0.5s ease-out;
  animation-fill-mode: forwards;
  /* animation-direction: reverse; */
}
@keyframes peak {
  100% {
    transform: translate3D(98%, -102%, 0);
  }
}

@keyframes peakAway {
  0% {
    transform: translate3D(98%, -102%, 0);
  }
  98% {
    transform: translateX(-98%, 98%, 0);
  }
}

/* @keyframes peakAway {

} */

/* .roundGuyHider {
  position: absolute;
  background-image: url("./images/bannerguy2.png");
  background-size: contain;
  width: 600px;
  height: 600px;
  
  bottom: -100%;
  left: -100%;
} */

/* .showRoundGuy {
  animation: peakUp 0.5s ease-in;
} */

@keyframes peakUp {
  0% {
    transform: translate3D(-100%, 200%, 0);
  }
  /* 50% {
    transform: translate3D(100px, -100px, 0);
  } */
}

@keyframes peakDown {
  0% {
    transform: translate3D(-100%, -200%, 0);
  }
}
@keyframes dropdown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes dropdownReverse {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-110%);
  }
}
