@import url("https://fonts.googleapis.com/css2?family=Irish+Grover&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

html {
  /* line-height: 1.15; */
  overflow: scroll;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  box-sizing: border-box;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "RoundGuysLight", "Poppins", "Roboto", Arial, sans-serif;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

body {
  margin: 0;
  font-family: "RoundGuysLight", "Poppins", "Roboto", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #00bcd4;
  font-size: 20px;
  font-weight: bold;
  color: white;
  height: 100%;
  top: 0;
  bottom: 0;
  box-sizing: border-box;
  image-rendering: pixelated;
  overflow-x: hidden;
  overflow-y: hidden;
  overscroll-behavior: none;
}

img,
svg {
  vertical-align: middle;
}

section {
  scroll-margin-top: 10rem;
}

/* #root {
  position: fixed;
} */
h1,
h2 {
  margin: 0px;
  padding: 0;
}

p {
  /* font-size: 24px; */
  font-size: 1.69vw;
  /* line-height: 2.3rem; */
}

ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

button {
  cursor: pointer;
}
