/* File: font.css */

@font-face {
  font-family: "Chicken-scratch";
  src: local("./Chicken-scratch"),
    url("./Chicken-scratch.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "RoundGuys";
  src: local("./RoundGuys.otf"), url("./RoundGuys.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "RoundGuysLight";
  src: local("./RoundGuysLight"), url("./RoundGuysLight.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "CalculatorFont";
  src: local("./CalculatorFont"), url("./CalculatorFont.otf") format("opentype");
  font-weight: 400;
}
