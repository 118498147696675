@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.mobileContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100%;
  font-family: "Poppins", "Roboto", sans-serif;
  margin-top: 1.5vh;
  padding-left: 3.5vw;
}

.mobileNavItem {
  font-family: "Poppins", "Roboto", sans-serif;
  font-size: 1.3rem;
  flex: 1;
  /* margin: auto; */
  text-align: center;
  text-transform: lowercase;
}

.mint-section-wrapper-mobile {
  position: absolute;
  top: 40%;
  left: 0%;

  width: 100%;
}

.mint-top-mobile {
}

.mint-top-content-mobile {
}

.teamContainerMobile {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.teamContentMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", "Roboto", sans-serif;
}
.teamAvatarMobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
}

.roadmap-team-bg-mobile {
  background: black;
  background-image: url("./images/background-team-mobile.png") !important;
  background-size: cover;
  image-rendering: pixelated;
  background-position: center;
  background-repeat: no-repeat;
}

.calc-image-container-mobile {
  position: relative;
  width: 100%;
  background-image: url("./images/Calculator.gif");
  height: 410px;
  width: 348px;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.calc-mint-amount-mobile > p {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  height: 100%;
  font-family: "calculatorfont";
  font-size: 1.5rem;
  align-items: center;
}

.mintUpContainer-mobile {
  left: 17.2%;
}
.decrement-mint-button-mobile {
  width: 45px;
}
.increment-mint-button-mobile {
  width: 45px;
}

.calc-mint-button-mobile {
  width: 229px;
  cursor: pointer;
}

.calc-total-supply-mobile {
  top: 14%;
}

.calc-total-supply-mobile > p {
  font-size: 2rem;
  font-family: "calculatorfont";
}
#mainLogoMobile {
  margin-top: 18vh;
  width: 20rem;
}

.roadmap-mobile-head {
  display: flex;
  justify-content: flex-start;
  color: white;
  font-size: 6vw;
}

.roadmap-mobile-subhead {
  display: flex;
  justify-content: flex-start;
  color: white;
  font-size: 7.4vw;
}

.roadmap-strip-mobile-desc {
  color: white;

  margin-bottom: 4rem;
  /* margin-left: 2rem; */
  margin-left: 0rem;
}
.roadmap-strip-mobile-desc > p {
  font-size: 3.75vw !important;

  line-height: 1.5rem;
}

.roadmap-strip-mobile-desc > span {
  font-size: 3.75vw !important;
  line-height: 1.5rem;
}

.finkels-img-mobile {
  width: 500px;
}

.airdrop-title-mobile {
  font-size: 4rem;
}

.airdrop-content-container-mobile {
  margin-left: 0rem;
}

.roadmap-strip-desc > h2 {
  margin: 1.3rem 0rem;
  /* font-size: 1.8rem; */
  font-size: 4vw;
}
.mobileLandingTextContainer {
  font-size: 1rem;
  padding: 20px;
  font-family: "Poppins", "Roboto", sans-serif;
}

.animationMobile {
  animation: dropdownMobile 0.5s forwards;
}

.animationReverseMobile {
  animation: dropdownReverseMobile 0.5s forwards;
}

.roadmap-details-mobile {
  background: transparent;
}

.roadmap-main-mobile {
  /* background: transparent; */
}
.roadmap-dropdown-mobile {
  position: fixed;
  display: grid;
  grid-template-columns: 1fr;
  width: 100vw;
  height: 100vh;
  background-image: url("./images/Space.gif");
  background-size: contain;
  image-rendering: pixelated;
  color: white;
  z-index: 100;
  overflow: hidden;
  animation-fill-mode: forwards;
  transform: translateY(-110%);
  will-change: transform, z-index;
}

.closeContainerMobile {
  position: absolute;
  padding: 0vw;
  top: 10%;
  left: -5%;
  z-index: 9999;
}
.closeMobile {
  font-size: 8vw;
  outline: none;
  color: rgba(255, 254, 254, 0.453);
  border: none;
  background: transparent;
  border-radius: 100%;
  cursor: pointer;
  font-weight: bolder;
}
@keyframes dropdownMobile {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-10%);
  }
}

@keyframes dropdownReverseMobile {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-110%);
  }
}
